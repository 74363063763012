<template>
  <div id="mainSlider">
    <div class="container-fluid">
      <!-- Add Edit Section -->
      <!-- @submit="onSubmit" @reset="onReset" -->
      <div v-if="show_land_img" @click="show_land_img = false"
           class="show-img d-flex align-items-center justify-content-center position-fixed">
        <img class="w-75" :src="land_img_path" alt="">
      </div>
      <div class="add-section" v-if="canAccess(['store-mainSlider','update-mainSlider'])">
        <b-modal id="mainSlider-modal"  hide-header-close @hidden="closeModal" :cancel-title="$t('close')"
                 :ok-title="$t('save')"
                 :title="$t('sidebar.mainSlider')" @ok="submit">
          <b-form class="d-grid grid-cols-1 gap-5">
            <div class="img-preview d-flex justify-content-center align-items-center">
              <img class="w-100 h-100" v-if="imgPreview" :src="imgPreview" alt="">
              <span v-else>{{ $t('img-preview') }}</span>
            </div>
            <div class="feild pt-3">
              <b-form-file
                type="text"
                @change="getImg($event)"
                :placeholder="$t('upload-img')"
                required
              ></b-form-file>
            </div>
          </b-form>
        </b-modal>
      </div>
      <!-- Tabel Section -->
      <div class="table-sections" style="white-space: nowrap;">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('sidebar.mainSlider') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if="canAccess(['store-mainSlider'])" variant="primary" @click="addInit" size="sm">{{
                $t('add')
              }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-table responsive v-if="canAccess(['get-mainSlider','update-mainSlider','delete-mainSlider'])"
                     :items="mainsliders.data" :fields="fields" class="mb-0 table-borderless">
              <template v-slot:cell(image)="data">
                <div class="details d-flex align-items-center" style="gap: 5px;">
                  <img @click="show_img(data.item.image)" :src="data.item.image" class="rounded" width="50"
                       height="50" alt="">
                </div>
              </template>
              <template v-slot:cell(actions)="data">
                <div class="actions-list d-flex align-items-center">
                  <b-button v-if="canAccess(['update-mainSlider'])" v-b-tooltip.top="$t('edit')" variant=" iq-bg-success"
                            @click="update(data.item)" size="md"><i class="ri-ball-pen-fill p-0"></i></b-button>
                  <!-- <b-button v-if="canAccess(['delete-mainSlider'])" v-b-tooltip.top="$t('remove')"
                            variant=" iq-bg-danger" size="md" @click="remove(data.item.id)"><i
                    class="ri-delete-bin-line p-0"></i></b-button> -->
                </div>
              </template>
            </b-table>
          </template>
        </iq-card>
      </div>
      <!-- Pagination Section -->
      <div class="pagination">
        <iq-card class="w-100 d-flex justify-content-center">
          <template v-slot:body>
            <b-pagination
              v-model="filterData.page"
              :total-rows="mainsliders.total"
              :per-page="mainsliders.per_page"
            ></b-pagination>
          </template>
        </iq-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'mainSlider',
  data () {
    return {
      show_land_img: false,
      land_img_path: '',
      imgPreview: '',
      filterData: {
        page: 1,
        name: ''
      },
      payload: {
        ar_name: '',
        en_name: '',
        kr_name: '',
        city_id: '',
        image: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('image'),
          key: 'image'
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('updated-date'),
          key: 'updated_at'
        }
        // {
        //   label: this.$t('actions'),
        //   key: 'actions'
        // }
      ]
    }
  },
  methods: {
    show_img (path) {
      this.show_land_img = true
      this.land_img_path = path
    },
    filtering () {
      this.filterData.page = 1
      this.getMainSliders(this.filterData)
    },
    addInit () {
      this.$bvModal.show('mainSlider-modal')
    },
    update (data) {
      Object.assign(this.payload, data)
      this.imgPreview = data.image_path
      this.$bvModal.show('mainSlider-modal')
    },
    remove (id) {
      this.confermMsg(() => {
        this.removemainSlider(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addMainSlider(this.returnData(this.payload)).then(() => {
          this.$bvModal.hide('mainSlider-modal')
          this.payload = this.resetObject(this.payload)
          this.getMainSliders()
        })
      } else {
        this.payload._method = 'PUT'
        delete this.payload.image_path
        if (typeof this.payload.image === 'string') {
          delete this.payload.image
        }
        this.updateMainSlider({
          id: this.payload.id,
          data: this.returnData(this.payload)
        }).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$bvModal.hide('mainSlider-modal')
          this.getMainSliders()
        })
      }
    },
    getImg (event) {
      this.imgPreview = URL.createObjectURL(event.target.files[0])
      this.payload.image = event.target.files[0]
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.imgPreview = ''
      this.payload = this.resetObject(this.payload)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getMainSliders(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.getCities({
      take: 100,
      page: 1,
      ar_name: '',
      en_name: '',
      kr_name: '',
      governorate_id: ''
    })
    this.getMainSliders()
  }
}
</script>
<style>
.add-new-img {
  font-size: 18px;
  font-weight: bold;
  color: #ccc;
  height: 200px;
  border: 2px dashed #ccc;
  cursor: pointer;
}

.img-preview {
  height: 200px;
  border: 2px dashed #ccc;
}

.img-preview img {
  object-fit: contain;
}

.show-img {
  background-color: rgba(0, 0, 0, .8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
