import $axios from '@/plugins/axios'
export default {
  state: {
    userData: {}
  },
  getters: {
    userData: state => state.userData
  },
  actions: {
    async loginUser ({ commit }, payload) {
      const response = await $axios.post('/auth/login', payload)
      commit('SORT_USERDATA', response.data)
    },
    async loginOut ({ commit }, payload) {
      const response = await $axios.post('/auth/logout', payload)
      commit('REMOVE_USERDATA', response.data)
    },
    async updateAuth ({ commit, state }, payload) {
      return await $axios.post('/auth/update-profile', {
        ...state.userData,
        ...payload
      })
    }
  },
  mutations: {
    SORT_USERDATA (state, data) {
      state.userData = data
      localStorage.setItem('booking-admin-info', JSON.stringify(data))
    },
    REMOVE_USERDATA (state, data) {
      return { state, data }
    }
  }
}
