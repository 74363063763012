<template>
  <b-container>
    <b-row>
      <b-col cols="6">
        <iq-card>
          <template v-slot:headerTitle>
            <h6 class="card-title text-center">{{$t('slider-data')}}</h6>

          </template>
          <template v-slot:body>
            <draggable
              class="list-group"
              tag="div"
              :list="payload"
              group="sliderBody"
              @change="log"
            >
            <div
                class="list-group-item d-flex align-items-center justify-content-between"
                style="cursor: grabbing"
                v-for="(data,index) in payload"
                :key="index"
                >
                <div class="data-content d-flex align-items-center" style="gap: 10px;">
                  <div class="img">
                      <img width="75" height="50" class="rounded-sm" :src="data.image" alt="">
                  </div>
                  <div class="info">
                      <h6 class="mb-0 ">
                          {{ data.title }}
                      </h6>
                      <span v-for="star in data.stars" :key="star" style="color: gold;">
                          <i class="ri-star-s-fill"></i>
                      </span>
                  </div>
                </div>
                <b-button v-b-tooltip.top="'Remove'" variant=" iq-bg-danger"  size="md" @click="payload.splice(index,1)"><i class="ri-delete-bin-line p-0"></i></b-button>

            </div>
            </draggable>
            <div class="form-controll d-flex justify-content-end py-3" v-if="payload.length !== 0">
                <b-button variant="primary" @click="submit" size="lg">{{$t('save')}}</b-button>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col cols="6">
        <iq-card>
          <template v-slot:headerTitle>
            <h6 class="card-title text-center">{{$t('income-hotel')}}</h6>
            <div class="filter" @change="filtering">
              <b-form-input
                  type="text"
                  min="1"
                  :placeholder="$t('filter-name')"
                  v-model="filterData.title"
                  required
                ></b-form-input>
            </div>
          </template>
          <template v-slot:body>
            <draggable
              class="list-group"
              tag="div"
              :list="hotelsList"
              group="sliderBody"
              @change="log"
            >
            <div
                class="list-group-item d-flex align-items-center"
                style="gap: 10px; cursor: grabbing"
                v-for="(data) in hotelsList"
                :key="data.name"
                >
                <div class="img">
                    <img width="75" height="50" class="rounded-sm" :src="data.image" alt="">
                </div>
                <div class="info">
                    <h6 class="mb-0 ">
                        {{ data.title }}
                    </h6>
                    <span v-for="star in data.stars" :key="star" style="color: gold;">
                        <i class="ri-star-s-fill"></i>
                    </span>
                </div>
            </div>
            </draggable>
          </template>
        </iq-card>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="hotels.total"
                  :per-page="hotels.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  name: 'slider body',
  data () {
    return {
      drag: true,
      filterData: {
        page: 1,
        title: '',
        hotel_code: '',
        governorate_id: ''
      },
      hotelsList: [],
      payload: []
    }
  },
  components: {
    draggable
  },
  computed: {
    pageData: {
      get () {
        return this.hotels.data.map(el => {
          return {
            image: el.image,
            id: el.id,
            title: el.title,
            stars: el.stars
          }
        })
      },
      set (value) {
        return value
      }
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getHotels(this.filterData)
    },
    log (data) {
      return data
    },
    testDrag (data) {
      this.updateSliders({
        id: data.item.attributes['data-id'].value,
        sort_order: data.newDraggableIndex + 1
      }).then(() => {
        this.getSliders()
      })
    },
    submit () {
      var payloadData = {
        main_slider_id: this.$route.params.id,
        sliders: this.payload.map((el, index) => {
          return {
            id: el.id,
            sort_order: index + 1
          }
        })
      }

      this.payload.map((el, index) => {
        return {
          id: el.id,
          sort_order: index + 1
        }
      })

      this.addSliderBody(payloadData).then(() => {
        this.$router.push('/slider')
      })
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getHotels(this.filterData).then(() => {
          this.hotelsList = this.hotels.data.map(el => {
            return {
              image: el.image,
              id: el.id,
              title: el.title,
              stars: el.stars
            }
          })
        })
      },
      deep: true
    }
  },
  mounted () {
    this.showSlider(this.$route.params.id).then(() => {
      this.slider.sliders.map(el => {
        this.payload.push(
          {
            image: el.child.image,
            id: el.child.id,
            title: el.child.title,
            stars: el.child.stars
          }
        )
      })
    })
    this.getHotels().then(() => {
      this.hotelsList = this.hotels.data.map(el => {
        return {
          image: el.image,
          id: el.id,
          title: el.title,
          stars: el.stars
        }
      })
    })
  }
}
</script>
