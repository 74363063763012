<template>
    <div id="governorates">
      <div class="container-fluid">
                <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-row>
                  <b-col cols="3">
                    <b-form-input
                      type="text"
                      :placeholder="$t('title')"
                      v-model="filterData.title"
                      required
                    ></b-form-input>
                  </b-col>
                  <b-col cols="3">
                    <v-select v-model="filterData.governorate_id" @change="filtering" :options="governorates.data" :reduce="(option)=>option.id" :label="`${$i18n.locale}_name`" />
                  </b-col>
                  <b-col cols="3">
                    <b-form-input
                      type="text"
                      :placeholder="$t('code')"
                      v-model="filterData.hotel_code"
                      required
                    ></b-form-input>
                    <!-- add button search -->
                  </b-col>
                  <b-button variant="primary" @click="filtering" size="xl">{{$t('search')}}</b-button>
                </b-row>
              </div>
            </template>
          </iq-card>
        </div>

        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('hotel-list') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button variant="primary" v-if="canAccess(['store-hotel'])" @click="$router.push('/add-hotel')" size="sm">{{ $t('add') }}</b-button>
            </template>
            <template v-slot:body>
              <b-table responsive v-if="canAccess(['get-hotel','show-hotel','update-hotel','delete-hotel'])" :items="hotels.data" :fields="fields" class="mb-0 table-borderless">
                  <template v-slot:cell(image)="data">
                    <img width="75" height="50" style="object-fit: cover;" class="rounded-sm" :src="data.item.image" alt="">
                  </template>
                <template v-slot:cell(show_in_website)="data">
                  <h5>
                    <!-- <b-badge :variant="HotelType.getByValue(data.item.show_in_website).classes || 'primary'">{{HotelType.getByValue(data.item.show_in_website).label.en}}</b-badge> -->
                    {{ data.item.show_in_website }}
                  </h5>
                </template>
                <template v-slot:cell(start_price)="data">
                    {{ data.item.start_price }} / {{ data.item.end_price }}
                  </template>
                <template v-slot:cell(actions)="data">
                    <div class="actions-list d-flex align-items-center">
                      <b-button v-if="canAccess(['show-hotel'])" v-b-tooltip.top="$t('show-data')" variant=" iq-bg-primary"  size="md" @click="showInit(data.item)"><i class="ri-eye-fill p-0"></i></b-button>
                      <b-button v-if="canAccess(['update-hotel'])" v-b-tooltip.top="$t('edit')" variant=" iq-bg-success" @click="update(data.item)"  size="md"><i class="ri-ball-pen-fill p-0"></i></b-button>
                      <!-- <b-button v-if="canAccess(['delete-hotel'])" variant=" iq-bg-danger" v-b-tooltip.top="$t('remove')"    size="md" @click="remove(data.item.id)"><i class="ri-delete-bin-line p-0"></i></b-button> -->
                    </div>
                  </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="hotels.total"
                  :per-page="hotels.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
import { HotelType } from '@/enums'
export default {
  name: 'hotels',
  data () {
    return {
      hotelData: {
        governorate: {},
        city: {}
      },
      filterData: {
        title: '',
        hotel_code: '',
        governorate_id: '',
        page: 1
      },
      payload: {
        title: '1',
        stars: 1,
        long: 2234,
        lat: 234,
        start_price: 2343,
        end_price: 2432,
        smoking_policy: 1,
        has_pool: 1,
        city_id: '',
        name: '',
        email: 'admin@hotel2',
        password: 123456
      }
    }
  },
  computed: {
    HotelType () {
      return HotelType
    },
    fields () {
      return [
        {
          label: this.$t('img'),
          key: 'image'
        },
        {
          label: this.$t('code'),
          key: 'code'
        },
        {
          label: this.$t('title'),
          key: 'title'
        },
        {
          label: this.$t('governorate'),
          key: 'governorate.' + this.$i18n.locale + '_name'
        },
        {
          label: this.$t('city'),
          key: 'city.' + this.$i18n.locale + '_name'
        },
        {
          label: this.$t('rate'),
          key: 'rate'
        },
        {
          label: this.$t('stars'),
          key: 'stars'
        },
        {
          label: this.$t('start-end-price'),
          key: 'start_price'
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('created-by'),
          key: 'created_by.name'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getHotels(this.filterData)
    },
    showInit (data) {
      this.$router.push(`/hotel/${data.id}`)
      this.$bvModal.show('hotels-modal')
    },
    update (data) {
      this.$router.push(`/add-hotel/${data.id}`)
      this.SET_HOTEL(data)
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeGovernorates(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addHotels(this.returnData(this.payload)).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$bvModal.hide('hotels-modal')
        })
      } else {
        this.updateHotels(this.returnData(this.payload)).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$bvModal.hide('hotels-modal')
        })
      }
    },
    changeStatus () {}
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getHotels(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.getCities()
    this.getGovernorates({
      take: 100,
      page: 1,
      ar_name: '',
      en_name: '',
      kr_name: ''
    })
    this.getHotels()
  }
}
</script>
